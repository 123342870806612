"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uriToResource = exports.resourceToUri = exports.ResourceUriRegex = exports.ResourceUriPattern = void 0;
exports.ResourceUriPattern = '^[a-zA-Z0-9_.:-]+$';
exports.ResourceUriRegex = new RegExp(exports.ResourceUriPattern);
var FIELD;
(function (FIELD) {
    FIELD["SERVICE"] = "srv";
    FIELD["RESOURCE_TYPE"] = "type";
    FIELD["RESOURCE_ID"] = "id";
})(FIELD || (FIELD = {}));
function resourceToUri(res) {
    if (!exports.ResourceUriRegex.test(res.resourceId)) {
        throw new Error("Invalid resourceId, must match pattern " + exports.ResourceUriPattern);
    }
    if (!exports.ResourceUriRegex.test(res.resourceType)) {
        throw new Error("Invalid resourceType, must match pattern " + exports.ResourceUriPattern);
    }
    if (!exports.ResourceUriRegex.test(res.serviceId)) {
        throw new Error("Invalid serviceId, must match pattern " + exports.ResourceUriPattern);
    }
    return [
        "res:" + FIELD.SERVICE + ":" + res.serviceId,
        FIELD.RESOURCE_TYPE + ":" + res.resourceType,
        FIELD.RESOURCE_ID + ":" + res.resourceId,
    ].join(',');
}
exports.resourceToUri = resourceToUri;
function uriToResource(res) {
    if (!res.startsWith('res:')) {
        throw new Error('Bad prefix');
    }
    var parts = res.substr(4).split(',');
    if (parts.length !== 3) {
        throw new Error('Invalid uri, part count wrong');
    }
    return {
        serviceId: parts[0].substr(FIELD.SERVICE.length + 1),
        resourceType: parts[1].substr(FIELD.RESOURCE_TYPE.length + 1),
        resourceId: parts[2].substr(FIELD.RESOURCE_ID.length + 1),
    };
}
exports.uriToResource = uriToResource;
