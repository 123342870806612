"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @class Authority will tie together the dictionnary of keys, the optional
 * certificate used to sign them, provision of said certificate and public key,
 * and the tie between authorization / authentication and certificate request
 */
var Authority = /** @class */ (function () {
    function Authority(issuer) {
        this.issuer = issuer;
    }
    return Authority;
}());
exports.default = Authority;
