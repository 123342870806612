"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rfc4648_1 = require("rfc4648");
var shared_1 = require("./shared");
function encode(str) {
    return rfc4648_1.base64url.stringify(str.split('').map(function (a) { return a.charCodeAt(0); }));
}
function decode(str) {
    return String.fromCharCode.apply(null, rfc4648_1.base64url.parse(str, { loose: true }));
}
exports.default = { encode: encode, decode: decode, escape: shared_1.escape, unescape: shared_1.unescape };
