"use strict";
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * class:    PromiseWrapper
 * purpose:  Will wrap promises returning functions to be called only once, and
 *           return the same result to any invocation that was made to it.
 * use case: define data source in a class, and ways to obtain it, without
 *           knowing the order of execution in advance, while still allowing any
 *           call to require any data.
 * caveat:   This approach could lead to dead lock, if 2 data source are
 *           requesting each other. Use the .wait() method to troubleshoot
 *           these, providing a timeout that should allow everything to be
 *           resolved had everything been available.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var PromiseWrapper = /** @class */ (function () {
    function PromiseWrapper() {
        this._promises = [];
    }
    /**
     * Return a new function that will call the handle only once, and return the
     * same promise to any subsequent call to it.
     * @param {function} handle method to wrap
     * @param {mixed} context what to use as "this"
     * @param {string} debugLabel used for troubleshooting purposes
     */
    PromiseWrapper.prototype.wrap = function (handle, context, debugLabel) {
        if (context === void 0) { context = null; }
        if (debugLabel === void 0) { debugLabel = ''; }
        var o = {
            promise: null,
            result: null,
            resolved: false,
            debugLabel: debugLabel,
        };
        this._promises.push(o);
        return function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (o.resolved) {
                        // debug(debugLabel, "already resolved, returning cached result");
                        return [2 /*return*/, o.result];
                    }
                    if (o.promise === null) {
                        // debug(debugLabel, "called for the first time, caching promise");
                        o.promise = handle.apply(context, args).then(function (r) {
                            o.result = r;
                            o.resolved = true;
                            return r;
                        });
                    }
                    else {
                        // debug(debugLabel, "already triggered, returning promise");
                    }
                    return [2 /*return*/, o.promise];
                });
            });
        };
    };
    /**
     * Returns the list of pending promises
     */
    PromiseWrapper.prototype.getPendings = function () {
        return this._promises.filter(function (p) { return p.resolved === false; });
    };
    /**
     * Check if there's any pending promises.
     * @param {function} onEachPending callback to handle pending issues
     */
    PromiseWrapper.prototype.hasPending = function (onEachPending) {
        var pendings = this.getPendings();
        if (typeof onEachPending === 'function') {
            pendings.forEach(onEachPending);
        }
        return pendings.length > 0;
    };
    /**
     * return a promise that will resolve once everything is finished
     * @param {integer} delay how long to wait before timing out, in
     *                        milliseconds. Leave blank to wait forever
     */
    PromiseWrapper.prototype.wait = function (delay) {
        var pendings = this.getPendings();
        if (pendings.length === 0) {
            return Promise.resolve();
        }
        // debug(
        //     `Waiting on ${pendings.length} promises`,
        //     pendings.reduce(p=>p.debugLabel||"unkown")
        // );
        var hasFinished = Promise.all(pendings.map(function (p) { return p.promise; }));
        if (delay) {
            return Promise.race([
                hasFinished,
                new Promise(function (resolve, reject) { return setTimeout(function () { return reject(new Error('Timeout')); }, delay); }),
            ]).then(function () {
                return;
            });
        }
        return hasFinished.then(function () {
            return;
        });
    };
    return PromiseWrapper;
}());
exports.default = PromiseWrapper;
