"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AbstractRSA = /** @class */ (function () {
    function AbstractRSA() {
    }
    AbstractRSA.prototype.supports = function (mode) {
        return true;
    };
    AbstractRSA.prototype.getMaxEncryptionLength = function () {
        return 1000;
    };
    return AbstractRSA;
}());
exports.default = AbstractRSA;
