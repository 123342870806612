"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wrapLine = void 0;
function wrapLine(str, maxWidth) {
    if (maxWidth === void 0) { maxWidth = 80; }
    var parts = [];
    for (var i = 0; i < str.length; i += maxWidth) {
        parts.push(str.substr(i, maxWidth));
    }
    return parts;
}
exports.wrapLine = wrapLine;
