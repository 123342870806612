"use strict";
/**
 * Helper functions to join strings with an arbitrary separator. This will also
 * ensure there's only 1 separator between each part
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.join = void 0;
function join(separator, part) {
    var parts = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        parts[_i - 2] = arguments[_i];
    }
    var result = part;
    // iterating over the additional parts
    for (var _a = 0, parts_1 = parts; _a < parts_1.length; _a++) {
        var part_1 = parts_1[_a];
        if (!part_1) {
            // skip empty items
            continue;
        }
        if (part_1.startsWith(separator)) {
            if (result.endsWith(separator)) {
                // duplicated separator, remove one
                result += part_1.substr(separator.length);
            }
            else {
                // only part starts with it, and result doesn't end with it. We're good
                result += part_1;
            }
        }
        else if (result.endsWith(separator)) {
            // only result ends with it, and part doesn't start with it. We're good
            result += part_1;
        }
        else {
            // missing separator, add it
            result += separator + part_1;
        }
    }
    return result;
}
exports.join = join;
