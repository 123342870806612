"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.to_unix_timestamp = exports.now = void 0;
function now() {
    return to_unix_timestamp(new Date());
}
exports.now = now;
function to_unix_timestamp(d) {
    return Math.floor(d.getTime() / 1000);
}
exports.to_unix_timestamp = to_unix_timestamp;
