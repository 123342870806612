/* eslint-disable @typescript-eslint/no-var-requires */
const base64 = require('binary-base64');
const pako = require('pako');
const shared = require('../b64/shared');

exports.encode = function encode(str) {
    return shared.escape(base64.encode(pako.deflate(str)));
};

exports.decode = function decode(str) {
    return pako.inflate(base64.decode(shared.unescape(str)), { to: 'string' });
};
