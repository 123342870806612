"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.escape = exports.unescape = void 0;
function unescape(str) {
    return (str + '==='.slice((str.length + 3) % 4)).replace(/-/g, '+').replace(/_/g, '/');
}
exports.unescape = unescape;
function escape(str) {
    return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}
exports.escape = escape;
