// eslint-disable-next-line @typescript-eslint/no-var-requires
const base64 = require('binary-base64');

const symbols = '0123456789abcdef'.split('').reduce((acc, cur, idx) => {
    acc[cur] = idx;
    return acc;
}, {});

exports.uuidToBin = uuidToBin;
function uuidToBin(uuid) {
    const bytes = uuid.toLowerCase().replace(/-/g, '');
    if (bytes.length % 2 === 1) {
        throw new Error('Expecting an even amount of hex caracter');
    }

    const bin = new Uint8Array(bytes.length / 2);
    for (let i = 0, j = 0; i < bin.length; i++, j += 2) {
        const a = symbols[bytes[j]];
        const b = symbols[bytes[j + 1]];
        if (a === undefined || b === undefined) {
            throw new Error('Invalid character at position ' + j);
        }
        bin[i] = (a << 4) | b;
    }
    return bin;
}

exports.uuidToB64 = function uuidToB64(uuid, trim) {
    // disable for the time being
    // return uuid;
    let r = base64.encode(uuidToBin(uuid));
    if (trim !== false) {
        r = r.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    }
    return r;
};
