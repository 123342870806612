"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Telemetry = void 0;
/**
 * This class aims to help determine bottleneck in application, and identify
 * which part optimization should be added to
 *
 * This is facilitated by allowing easy timing of:
 *  - promises
 *  - manual snapshot
 *
 * Also, this ideally would allow to store all call telemetry together for
 * aggregate analysys
 *
 * Nested telemetry is also handled, allowing bigger chunk to be further split
 * to help zero on the problematic part
 *
 * Parallel execution is also handled, allowing identification of which of a
 * list of async code is making everything else wait
 *
 * desired API:
 * ```
 * const t = new Telemetry()
 *
 * // parallel telemetry sample
 * const parallel_telemetry = t.fork('label-for-this-execution')
 * // parallel code
 * await Promise.all([
 *   parallel_telemetry.wrap(
 *      'label-for-this-promise',
 *      (this_branch_telemetry)=>
 *        // return the promise to wait on here, optionally using
 *        // `this_branch_telemetry` to further split telemetry
 *           invokePromiseFunctionHere(this_branch_telemetry)
 *   )
 * , ...
 * ])
 *
 *
 * ```
 *
 *
 * For better result, only use sequence or fork on a telemetry instance. If both
 * are being used, parallel instances will be put in sequences.
 *
 * if a sequence is added after forks have been created, another sequence will
 * be added before the current requested sequence, and will be passed all
 * current forks.
 *
 * If a fork is added after a sequence is added, it will be added to the last
 * sequence created (as long as it is not ended, otherwise a new sequence will
 * be created)
 */
var Telemetry = /** @class */ (function () {
    function Telemetry(label, parentTelemetry, branches) {
        var _a;
        var _this = this;
        this.endedAt = null;
        this.branches = [];
        this.sequences = [];
        this.endError = null;
        this.label = label;
        this.parent = parentTelemetry;
        if (typeof branches !== 'undefined' && typeof parentTelemetry !== 'undefined') {
            (_a = this.branches).push.apply(_a, branches);
            // get first createdAt
            this.createdAt = branches.reduce(function (prev, curr) { return (curr.createdAt < prev ? curr.createdAt : prev); }, Telemetry.now());
            // get last endedAt
            this.endedAt = branches.reduce(function (prev, curr) {
                if (curr.endedAt === null) {
                    console.error("Telemetry error for " + _this.fullLabel + ", should not create new sequence with un-finished forks...");
                    return prev;
                }
                return curr.endedAt > prev ? curr.endedAt : prev;
            }, Telemetry.now());
        }
        else {
            this.createdAt = Telemetry.now();
        }
    }
    // Single method used to get current Date object. Mock or override for test
    // purposes
    Telemetry.now = function () {
        return new Date();
    };
    Telemetry.prototype.toJSON = function () {
        return {
            label: this.label,
            createdAt: this.createdAt,
            endedAt: this.endedAt,
            endError: this.endError,
            branches: this.branches,
            sequences: this.sequences,
        };
    };
    /**
     * create a new instance of telemetry, and automatically end when the promise
     * returned by the promisable method resolves or rejects. the value of error
     * will be passed allong
     */
    Telemetry.prototype.fork = function (label, promisable) {
        var lastSequenceId = this.sequences.length - 1;
        if (lastSequenceId >= 0) {
            if (Telemetry.strict === true) {
                throw new Error("Telemetry strict mode on, cannot fork " + this.fullLabel + ", sequences already present");
            }
            // add it to the last sequence, if it wasn't ended yet
            if (!this.sequences[lastSequenceId].hasEnded) {
                return this.sequences[lastSequenceId].fork(label, promisable);
            }
            // otherwise, create a new sequence and add this fork to it
            else {
                this.beforeNewSequence();
                var seq = new Telemetry('Branches', this);
                this.sequences.push(seq);
                return seq.fork(label, promisable);
            }
        }
        else {
            var _a = this.spawn(label, promisable), telemetry = _a.telemetry, launch = _a.launch;
            this.branches.push(telemetry);
            return launch();
        }
    };
    /**
     * create a new instance of telemetry, and automatically end when the promise
     * returned by the promisable method resolves or rejects. the value of error
     * will be passed allong
     *
     * When formated, these will be added next to each other
     *
     * If a previous sequence was not ended, calling this method will end it
     * before starting the new one
     */
    Telemetry.prototype.seq = function (label, promisable) {
        this.beforeNewSequence();
        var _a = this.spawn(label, promisable), telemetry = _a.telemetry, launch = _a.launch;
        this.sequences.push(telemetry);
        return launch();
    };
    /**
     * Similar to sequence, but does not wrap a promise. will be ended when the
     * next stamp is received
     */
    Telemetry.prototype.stamp = function (label) {
        this.beforeNewSequence();
        var telemetry = new Telemetry(label, this);
        this.sequences.push(telemetry);
        return telemetry;
    };
    /**
     * Used internaly, cleanup previous sequence if required, should be called
     * before adding a new entry to the sequences array
     */
    Telemetry.prototype.beforeNewSequence = function () {
        // end the last sequence if it wasn't already done
        var lastSequenceId = this.sequences.length - 1;
        if (lastSequenceId >= 0 && !this.sequences[lastSequenceId].hasEnded) {
            this.sequences[lastSequenceId].ended();
        }
        // if there were branches, add them to a sequence
        if (this.branches.length) {
            if (Telemetry.strict === true) {
                throw new Error("Telemetry strict mode on, cannot sequence " + this.fullLabel + ", forks already present");
            }
            var prevBranchSeq = new Telemetry('Branches', this, this.branches.splice(0, this.branches.length));
            this.sequences.push(prevBranchSeq);
        }
    };
    /**
     * Used internaly to create a new instance of telemetry and wrap the
     * promisable.
     * Telemetry retrurned should be stored for future use
     */
    Telemetry.prototype.spawn = function (label, promisable) {
        var _this = this;
        var telemetry = new Telemetry(label, this);
        return {
            telemetry: telemetry,
            launch: function () { return __awaiter(_this, void 0, void 0, function () {
                var result, err_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, promisable(telemetry)];
                        case 1:
                            result = _a.sent();
                            telemetry.ended();
                            return [2 /*return*/, result];
                        case 2:
                            err_1 = _a.sent();
                            telemetry.endedWithError(err_1);
                            throw err_1;
                        case 3: return [2 /*return*/];
                    }
                });
            }); },
        };
    };
    /**
     * Indicate the telemetry capture is  completed
     */
    Telemetry.prototype.ended = function () {
        if (this.endedAt === null) {
            this.endedAt = Telemetry.now();
        }
        else {
            // console.warn(`Double ending of Telemetry ${this.fullLabel}`);
        }
        // ensure all child telemetry are also ended
        this.endAllChild();
    };
    Telemetry.prototype.endAllChild = function () {
        for (var _i = 0, _a = this.branches; _i < _a.length; _i++) {
            var b = _a[_i];
            b.endedByParent();
        }
        for (var _b = 0, _c = this.sequences; _b < _c.length; _b++) {
            var s = _c[_b];
            s.endedByParent();
        }
    };
    Telemetry.prototype.endedByParent = function () {
        if (this.endedAt === null) {
            this.ended();
        }
        else {
            this.endAllChild();
        }
    };
    /**
     * Indicate the telemetry capture is  completed, but also notes that it ended
     * with an error
     */
    Telemetry.prototype.endedWithError = function (err) {
        this.endError = err;
        this.ended();
    };
    Object.defineProperty(Telemetry.prototype, "fullLabel", {
        get: function () {
            return (this.parent ? this.parent.fullLabel + '::' : '') + this.label;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Telemetry.prototype, "runtime", {
        /**
         * return the runtime, in millisecond
         */
        get: function () {
            if (this.endedAt) {
                return this.endedAt.getTime() - this.createdAt.getTime();
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Telemetry.prototype, "startOffsetFromParent", {
        get: function () {
            if (this.parent) {
                return this.createdAt.getTime() - this.parent.createdAt.getTime();
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Telemetry.prototype, "hasEnded", {
        get: function () {
            return this.endedAt === null ? false : true;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Return a nicely formated string of all telemetry registered from this
     * instance
     * @param levelIndent the string to use to indent each nested level
     * @param currentIndent current indentation, when recursively called
     * @returns
     */
    Telemetry.prototype.format = function (levelIndent, currentIndent) {
        if (levelIndent === void 0) { levelIndent = '..'; }
        if (currentIndent === void 0) { currentIndent = ''; }
        var offsetFromParent = this.startOffsetFromParent;
        var startOffset = offsetFromParent === null ? '' : " (+" + Telemetry.FormatRuntime(offsetFromParent) + ")";
        return __spreadArrays([
            "" + currentIndent + Telemetry.FormatRuntime(this.runtime) + " " + this.label + startOffset
        ], Telemetry.formatTelemetries(this.branches, levelIndent, currentIndent, '|', '+'), Telemetry.formatTelemetries(this.sequences, levelIndent, currentIndent, '.', '+')).join('\n');
    };
    Telemetry.formatTelemetries = function (telemetries, levelIndent, currentIndent, otherIdentifier, longuestIdentifier) {
        var longuestRuntime = telemetries.reduce(function (prev, b, idx) {
            var r = b.runtime;
            return r && r > prev.runtime ? { runtime: r, idx: idx } : prev;
        }, { idx: -1, runtime: 0 });
        return telemetries.map(function (b, idx) {
            var parallelSeparator = idx === longuestRuntime.idx ? longuestIdentifier : otherIdentifier;
            return b.format(levelIndent, "" + currentIndent + parallelSeparator + levelIndent);
        });
    };
    /**
     * Given a value in milliseconds, will format it into human friendly value
     * @param runtime_in_ms Value to format
     * @param steps configuration of
     */
    Telemetry.FormatRuntime = function (runtime_in_ms, steps) {
        if (steps === void 0) { steps = [
            { unit: 'ms', multiplier: 1000 },
            { unit: 's', multiplier: 60 },
            { unit: 'm', multiplier: 60 },
            { unit: 'h' },
        ]; }
        if (runtime_in_ms === null) {
            return 'incomplete';
        }
        var rest = runtime_in_ms;
        var results = [];
        for (var _i = 0, steps_1 = steps; _i < steps_1.length; _i++) {
            var step = steps_1[_i];
            if (typeof step.multiplier === 'undefined' || rest < step.multiplier) {
                results.unshift("" + rest + step.unit);
                break;
            }
            else {
                var newRest = Math.floor(rest / step.multiplier);
                var thisValue = rest - newRest * step.multiplier;
                results.unshift("" + thisValue + step.unit);
                rest = newRest;
            }
        }
        return results.join(' ');
    };
    // when true, will not allow sequences and branches to be created on the same
    // telemetry instance. Set to false to allow best guess to be made
    Telemetry.strict = true;
    return Telemetry;
}());
exports.Telemetry = Telemetry;
