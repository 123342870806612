"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlParser = void 0;
var URL_REGEX_TEXT = '([^:]+)://' + // scheme
    '([^/:?#]+)' + // hostname
    '(?::([0-6]?[0-9]{1,4}))?' + // port
    '(/[^?#]+)?' + // path
    '([?][^#]+)?' + // query
    '(#.+)?'; // anchor
var URL_REGEX = new RegExp(URL_REGEX_TEXT);
var UrlParser = /** @class */ (function () {
    function UrlParser(url) {
        this.url = url;
        var matches = url.match(URL_REGEX);
        this.matches = matches === null ? undefined : matches;
    }
    Object.defineProperty(UrlParser.prototype, "valid", {
        get: function () {
            return typeof this.matches !== 'undefined';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UrlParser.prototype, "scheme", {
        get: function () {
            return this.matches && this.matches[1];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UrlParser.prototype, "hostname", {
        get: function () {
            return this.matches && this.matches[2];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UrlParser.prototype, "port", {
        get: function () {
            return this.matches && this.matches[3];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UrlParser.prototype, "origin", {
        get: function () {
            if (this.scheme && this.hostname) {
                var result = this.scheme + "://" + this.hostname;
                if (this.port) {
                    result += ":" + this.port;
                }
                return result;
            }
            return;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UrlParser.prototype, "path", {
        get: function () {
            return this.matches && this.matches[4];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UrlParser.prototype, "query", {
        get: function () {
            return this.matches && this.matches[5];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UrlParser.prototype, "anchor", {
        get: function () {
            return this.matches && this.matches[6];
        },
        enumerable: false,
        configurable: true
    });
    return UrlParser;
}());
exports.UrlParser = UrlParser;
